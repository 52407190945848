import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL;

export const TestListCategory = async()=>{
    try {
        // console.log("hi")
        const response = await axios.get(`${baseUrl}/test-category/`)
        // console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
    
}


export const TestListSubCategory = async(testName)=>{
    try {
        // console.log(testName,"hi")
        const response = await axios.get(`${baseUrl}/test-category/subcategory/${testName}/`)
        // console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
    
}


export const AddTestListSubCategory = async(data)=>{
    console.log(data,"datawass")
    try {
        // console.log(testName,"hi")
        const response = await axios.post(`${baseUrl}/test-category/subcategory/`,data)
        // console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
    
}


export const DeleteTestListSubCategory = async(id)=>{
    console.log(id,"datawass")
    try {
        // console.log(testName,"hi")
        const response = await axios.delete(`${baseUrl}/test-category/subcategory/${id}`)
        // console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
    
}





export const TestPaperList1 = async(testName)=>{
    try {
        // console.log(testName,"hi")
        const response = await axios.get(`${baseUrl}/test-category/topic-list/${testName}/`)
        // console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
    
}



export const AddTestPaperList1 = async(data)=>{
    console.log("hi")
    try {
        // console.log(testName,"hi")
        const response = await axios.post(`${baseUrl}/test-category/topic-list/`,data)
        // console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
    
}


export const DeleteTestPaperList1 = async(id)=>{
    console.log("hi")
    try {
        // console.log(testName,"hi")
        const response = await axios.delete(`${baseUrl}/test-category/topic-list/${id}`)
        // console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
    
}


export const TestQuestionsList = async(id)=>{
    try {
        // console.log(id,"hi")
        const response = await axios.get(`${baseUrl}/test-category/test-start/${id}/`)
        // console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
    
}



export const AddTestQuestionsList = async(data)=>{
    console.log(data,"data")
    try {
        // console.log(id,"hi")
        const response = await axios.post(`${baseUrl}/test-category/test-start/`,data)
        // console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
    
}

export const DeleteTestQuestionsList = async(id)=>{
    console.log(id,"data")
    try {
        // console.log(id,"hi")
        const response = await axios.delete(`${baseUrl}/test-category/test-start/${id}`)
        // console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
    
}