import React from 'react';
import { useQuery } from 'react-query';
import { JobNameListShow } from './api/Api';
import AdSense from '../components/ads/AdSense';

export const JobUpdate = () => {
  const { data, isLoading, error } = useQuery(['joblist'], JobNameListShow);
  console.log(data, "data");

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold text-center mb-8 text-indigo-700">Latest Updates</h1>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        
        {/* AdSense on the side */}
        {/* <div className="sm:col-span-2 lg:col-span-1">
          <AdSense />
        </div> */}

        {/* Job Updates */}
        {data?.map((item, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 border border-custom-yellow">
            <h2 className="text-2xl font-semibold text-white text-center p-2 font-custom-fontnew w-full mb-4 bg-custom-yellow">
              {item.category_name}
            </h2>
            <ul className="space-y-2">
              {item?.job_names?.map((items, idx) => (
                <li key={idx} className="text-gray-700 text-lg">
                <a href={`/job/job-details/${items?.id}`}>
                  <span>&rarr;</span> {items?.name}
                </a>
              </li>
              ))}
            </ul>
          </div>
        ))}

      </div>

      {/* AdSense at the bottom */}
      <div className="mt-6">
        
        <AdSense />
      </div>
    </div>
  );
};
