import React, { useState } from 'react';
import { CgProfile } from "react-icons/cg";
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from "react-icons/fa";
import { skillvibes } from '../home/Image';
// import { useQuery } from 'react-query';
// import { MeApi } from '../account/AccountApi';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const Navbar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    // const { data: userdata } = useQuery(['token'], () => MeApi());
    // console.log(userdata,"userdata")
    // let accessToken = localStorage.getItem('authToken');
    const data = [
        { name: "Home", href: '/' },
        { name: "Job Update", href: 'job/job-update' },
        { name: "B.Tech", href: '/cse-language' },
        { name: "Physics", href: '/physics-class' },
        // { name: "Chemistry", href: '/chemistry-class' },
        // { name: "Biology", href: '/biology-class' },
        { name: "Test", href: '/test-series/test' },
        { name: "Project", href: '/project/mini-project' },
    ];

    // Conditionally add the "Dashboard" link if user_type is 2
    // if (userdata && userdata.user.user_type === 2) {
    //     data.push({ name: "Dashboard", href: `/test-series/test-list-admin/${accessToken}` });
    //     data.push({ name: "Job Post", href: `/job/job-admin/${accessToken}` });
    // }

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div className='w-full h-[70px] flex justify-between items-center md:justify-around bg-custom-yellow font-custom-fontnew text-white'>
            <div className="flex items-center">
                <div className="flex items-center space-x-3 text-xl font-serif font-bold">
                    <img src={skillvibes} alt="skillvibes logo" className="w-14 h-14 sm:w-16 sm:h-16 md:w-20 md:h-20" />
                    <span className="text-white">SkillVibes</span>
                </div>
            </div>
            <div className='hidden md:flex justify-center'>
                {
                    data.map((item, index) => (
                        <ul className='flex' key={index}>
                            <Link to={item.href} className='hover:bg-custom-pink hover:text-black hover:rounded-lg'>
                                <li className='font-xl p-4 font-serif font-bold'>{item.name}</li>
                            </Link>
                        </ul>
                    ))
                }
            </div>
            <div className='flex justify-center items-center'>
                {/* <p className='font-xl p-4 font-serif font-bold'><CgProfile size={30} /></p>
                {
                    userdata ? (
                        <p className='font-xl p-4 font-serif font-bold'>{userdata.user.name}</p>
                    ) : (
                        <Link to='/login/'>
                            <p className='font-xl p-4 font-serif font-bold'>Sign Up</p>
                        </Link>
                    )
                } */}
                <div className='md:hidden' onClick={toggleSidebar}>
                    {sidebarOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
                </div>
            </div>
            {sidebarOpen && (
                <div className='fixed inset-0 bg-gray-800 bg-opacity-50 z-50'>
                    <div className='fixed top-0 left-0 w-64 h-full bg-custom-yellow font-custom-fontnew text-white shadow-lg z-50'>
                        <div className="flex items-center justify-between p-4">
                            <p className="text-2xl font-serif font-bold">SkillVibes</p>
                            <FaTimes size={30} onClick={toggleSidebar} />
                        </div>
                        <div className='flex flex-col'>
                            {
                                data.map((item, index) => (
                                    <Link to={item.href} key={index} onClick={toggleSidebar} className='hover:bg-custom-pink hover:text-black hover:rounded-lg'>
                                        <li className='font-xl p-4 font-serif font-bold border-b'>{item.name}</li>
                                    </Link>
                                ))
                            }
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
