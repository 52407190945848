import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { JobDetailsShow } from './api/Api';
import AdSense from '../components/ads/AdSense';

export const JobDetails = () => {
    const { id } = useParams();
    console.log(id, "id");

    const { data } = useQuery(['jobdetail', id], () => JobDetailsShow(id));
    console.log(data, "data");

    return (
        <div className="min-h-screen bg-gray-50 py-10 px-4">
            <div className="max-w-5xl mx-auto">
                <h1 className="text-4xl font-bold text-center mb-10 text-gray-800">Job Details</h1>
                <AdSense/>
                {data?.map((item, index) => (
                    <div
                        key={index}
                        className="bg-white shadow-md rounded-lg p-6 mb-6 hover:shadow-lg transition-shadow duration-300"
                    >
                        {/* Job Title */}
                        <h2 className="text-2xl font-semibold mb-4 text-gray-700">Job #{id}</h2>

                        {/* Job Details */}
                        <div
                            className="text-gray-600 text-base leading-relaxed"
                            dangerouslySetInnerHTML={{ __html: item.job_details }}
                        />
                    </div>
                ))}

                {/* No Job Details Found */}
                {!data?.length && (
                    <p className="text-center text-gray-500 text-lg">No job details found.</p>
                )}
                <AdSense/>
            </div>
        </div>
    );
};
