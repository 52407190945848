import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router'
import { TestSeries } from './TestSeries'
import { TestSeriesDetail } from './TestSeriesDetail'
// import { TestSidebaar } from './TestSidebaar'
import { TestSeriesList } from './TestSeriesList'
import { TestSubList } from './TestSubList'
import { TestListAdmin } from '../dashboard/TestListAdmin'
import { TestListSubAdmin } from '../dashboard/TestListSubAdmin'
import { TestPaperList } from '../dashboard/TestPaperList'
import { TestQuestionsAdmin } from '../dashboard/TestQuestionsAdmin'

export const TestScreen = () => {
  return (
    <Suspense>
      {/* <TestSidebaar/> */}
        <Routes>
            <Route path='/test/'  element={<TestSeries/>}/>
            <Route path='test-list'  element={<TestSeriesList/>}/>
            <Route path='test-sub-start/:id'  element={<TestSubList/>}/>
            <Route path='test-start/:id'  element={<TestSeriesDetail/>}/>




            <Route path='test-list-admin/:accessToken' element={<TestListAdmin/>}/>
            <Route path='test-sub-admin/:id'element={<TestListSubAdmin/>}/>
            <Route path='test-paper-admin/:id'element={<TestPaperList/>}/>
            <Route path='test-question-admin/:id'element={<TestQuestionsAdmin/>}/>
        </Routes>
    </Suspense>
  )
}
