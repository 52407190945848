import React, { useState } from 'react';
import { TestSidebaar } from './TestSidebaar';
import { TestSeriesList } from './TestSeriesList';
import { TestSubList } from './TestSubList';
import AdSense from '../../components/ads/AdSense';
import { TestListHome } from './TestListHome';

export const TestSeries = () => {
  const [testName, setTestName] = useState();
  const [testPaper, setTestPaper] = useState(null);

  const [toggle,setToggle] = useState(false)

  // console.log(testName?.id, "testName");
  // console.log(testPaper, "testPaper");

  return (
    <div className="flex">
      {/* Sidebar for Chapters */}
      <div className={`w-64 lg:block ${toggle? "block":"hidden"}`}>
        <TestSidebaar setTestName={setTestName} 
        setToggle={setToggle}
        />
      </div>

      <div className="flex-grow min-h-screen bg-gray-100 p-4">
        <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6 mx-auto">
        <h1 className='lg:hidden block cursor-pointer mb-4 text-blue-600' onClick={() => setToggle(!toggle)}>
            {toggle ? 'Close Test' : 'Open Test'}
          </h1>

          <p className="breadcrumb text-gray-600 mb-4">
            Home » <span className="breadcrumb-link text-blue-600">{testName?.name}</span>
          </p>
          {/* <h2 className="react-question text-4xl font-semibold mb-4">data?.chapter_name</h2> */}

          {/* Render TestSubList */}
          {/* <TestSubList testPaper={testPaper} /> */}

          {/* Show TestSeriesList only if testName is selected */}
          {
            testName? <TestSeriesList testName={testName.id} setTestPaper={setTestPaper} /> : <TestListHome setTestName={setTestName} />
          }
          
            
          
        </div>
      </div>
      <div>
        <AdSense/>
      </div>
    </div>
  );
};
