import React from 'react';
import { useQuery } from 'react-query';
import { TestListCategory } from '../api/Api';
import { useNavigate } from 'react-router';
import AdSense from '../../components/ads/AdSense';

export const TestSidebaar = ({setTestName ,setToggle}) => {
  // Fetch the data using useQuery hook
  const { data, isLoading, error } = useQuery(['testcategory'], TestListCategory);

  // Handle loading state
  const navigate = useNavigate()
  
  const handleChange = (item)=>{
    if(setTestName){
      setTestName(item)
      setToggle(false)
    }else{
      navigate('/test-series/test/')
      setToggle(false)
    }
  }
  if (isLoading) return <div>Loading...</div>;
  // Handle error state
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
    <div className="w-64 h-screen bg-white p-4 shadow-lg overflow-x-hidden">
      {/* <h1 className="text-2xl font-bold mb-4 text-center">Chapters</h1> */}
      <ul className="space-y-3">
        {data?.map((item, index) => (
          <li key={index}>  {/* Add unique key */}
            <a href={item.href}>
              <h2 className="text-sm text-gray-400 font-medium cursor-pointer hover:text-blue-600" onClick={()=>handleChange(item)}>
                {item?.name}
              </h2>
            </a>
          </li>
        ))}
      </ul>
    </div>
    <div><AdSense/></div>
    </div>
  );
};
