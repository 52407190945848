import React, { useState,useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { HtmlData} from '../../api/Api';
import { useLocation } from 'react-router-dom';
import { NotFound } from '../../../../404page/NotFound';
import AdSense from '../../../../components/ads/AdSense';

export const Html = () => {
    const { pathname } = useLocation();
    const [page, setPage] = useState(1);
    const notesRef = useRef(null)

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [page]);
  
  const { data, isLoading, isError, error } = useQuery(['data', page], () => HtmlData(page));

  const handlePreviousPage = () => {
      setPage((prevPage) => Math.max(prevPage - 1, 1));
        if (notesRef.current) {
        notesRef.current.scrollIntoView({ behavior: 'smooth' });
      }
  };
console.log(data,"data",page)
  const handleNextPage = () => {
      setPage((prevPage) => prevPage + 1);
        if (notesRef.current) {
        notesRef.current.scrollIntoView({ behavior: 'smooth' });
      }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error: {error.message}</div>;
  return (
    <div className="main-container">
        {
            Array.isArray(data?.data) && data?.data.length === 0 ?(<NotFound/>):(

            <div className="container">
                <p className="breadcrumb">
                    Home » <span className="breadcrumb-link">Programming</span> » <span className="breadcrumb-current">What is HTML and What Can It Do?</span>
                </p>
                {data?.data?.map((alldata,index) => (
                    <div key={alldata.id} className="qa-container">
                        <p className="react-question text-gray-600">Q{index+1}. {alldata?.questions}</p>
                        <p className="react-answer text-gray-600">Answer: <span dangerouslySetInnerHTML={{ __html: alldata.answers }} /></p>
                        {alldata?.code && (
                            <div className="code-container">
                                <code>
                                    <pre>{alldata?.code}</pre>
                                </code>
                            </div>
                        )}
                        <div>
                            <AdSense/>
                        </div>
                    </div>
                ))}
                <div className="pagination">
                    <button
                        type="button"
                        onClick={handlePreviousPage}
                        disabled={page === 1}
                        className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded ${page === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}>
                         Previous
                    </button>
                    <span>{`Page ${page} of ${data?.total_pages}`}</span>
                    <button
                        type="button"
                        onClick={handleNextPage}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Next
                    </button>
                </div>
            </div>
            )
        }
        </div>
  )
}
